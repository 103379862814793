import React, { useState, useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import Header from '../../components/Header';
import { AuthContext } from '../../context/Auth';
import { ConsultationContext } from '../../context/Consultation';
import { AnalyticsContext } from '../../context/Analytics';
import { getAstroDetail, getWebConfig } from '../../services/apiServices';
import { capitalize, processLanguage } from '../../util/functions';
import i from '../../staticAssets/user.png';

function getColor(i) {
    const colors = ["purple-400", "red-400", "yellow-400", "green-400", "gray-400"]
    return colors[(i + 1) % colors.length];
}

function getEducationText(ob) {
    if (ob) {
        let t = ""
        for (let i = 0; i < ob.length; i++) {
            t = t + ob[i]?.name + "\n" + (ob[i]?.info ?? "") + ' ';
        }
        return t;
    }
    else {
        return "";
    }
}

function Astrologers({params}) {
    const id = params.id;
    const [detail, setdetail] = useState({});
    const {initiateCall, initiateChat} = useContext(ConsultationContext) || {initiateCall: () => {}, initiateChat: () => {}};
    const [fetchTrigger, setfetchTrigger] = useState(0);
    const { user, setshowLogin } = useContext(AuthContext) || {user: {}, setshowLogin:()=>{}};
    const {gtmCustomEvent ,gtmPageViewEvent } = useContext(AnalyticsContext) || {gtmCustomEvent: () => {}, gtmPageViewEvent: () => { } };
    const [isMount, setMount] = useState(false);
    const [config, setConfig] = useState({})

    
    useEffect(() => {
        gtmPageViewEvent(window.location.pathname,window.location.host, user.meesho_userId);
        gtmCustomEvent('astro_page_view', {path_name:`/astrologers/${id}`});
        setMount(true);
    },[]);

    const [host, sethost] = useState("");
    useEffect(() => {
      sethost(window.location.host);
    }, []);
    useEffect(() => {
      const callConfig = async () => {
        try {
          let configDataResponse;
          const specialHosts = [
            'localhost:8000',
            process.env.GATSBY_JIO_BASE,
            process.env.GATSBY_MEESHO_BASE,
            process.env.GATSBY_NAMAH_BASE,
            process.env.GATSBY_LOKAL_BASE,
            process.env.GATSBY_BODHI_BASE,
            
          ];
    
          if (specialHosts.includes(host)) {
            configDataResponse = await getWebConfig(host);
          }
          //  else {
          //   configDataResponse = await getWebConfig(host);
          // }
    
          if (configDataResponse) {
            setConfig(configDataResponse?.payLoad);
          }
        } catch (error) {
          console.error("Error fetching config data:", error);
        }
      };
    
      callConfig();
    }, [host]);
    useEffect(() => {
        const fn = async () => {
            let res = await getAstroDetail(id);
            if (res.statusCode === 200) {
                setdetail({ ...res.payLoad.astrologerDetail })
            }
            else {
                navigate('/consultAstro', {
                    replace: true,
                })
            }
        };
        fn();
        let t = setTimeout(() => {
            setfetchTrigger(fetchTrigger + 1);
        }, 5000);

        return () => { clearTimeout(t) }
    }, [fetchTrigger]);
    
    if(!isMount) {
        return(
            <div></div>
        )
    }
    return (
        <div className="flex flex-col h-screen worksans">

            <Header />
            <main className="flex-1 overflow-y-auto">
                <div className="flex flex-col lg:flex-row">
                    <div className="w-full md:my-10 md:pb-10 lg:mx-10 md:rounded-xl overflow-hidden md:border">
                        <div className="relative block h-[27rem]">
                            <div className="bg-gradient-to-r from-[#ff4c41]/20 to-orange-500/50 h-44">
                            </div>

                            <div className="absolute top-32 w-full flex justify-center">
                                <div className="w-10/12 bg-white rounded-xl shadow flex flex-col items-center">
                                    <h1 className="mt-20 font-bold text-xl text-gray-800">
                                        {detail?.name ?? ""}
                                    </h1>
                                    <div className="w-full flex flex-col">
                                        <p className="text-gray-500 text-center">
                                            {detail?.subHeading ?? ""}
                                        </p>
                                    </div>
                                    <div className="w-full flex flex-col">
                                        <h1 className="text-gray-500 text-center">
                                            {'Languages: ' + processLanguage(detail?.languages)}
                                        </h1>
                                    </div>
                                    <div className="flex m-2 justify-around w-full flex-wrap">
                                        {detail?.category?.map((v, i) => {
                                            return (<div className={"rounded-xl border m-1 py-1 px-2 border-orange-600"} key={i}>
                                                <h1 className={"text-xs text-orange-600"}>{capitalize(v)}</h1>
                                            </div>)
                                        })
                                        }
                                    </div>
                                    <div className="flex flex-1 bg-red-50 justify-around w-full items-center rounded-b-xl py-2">
                                        <div className="flex flex-col items-center">
                                            <h1 className="font-semibold text-sm md:text-base">{(detail?.experience?.years?? "") + ' years+'}</h1>
                                            <h2 className="text-gray-700 text-sm md:text-base">Experience</h2>

                                        </div>
                                        <div className="flex flex-col items-center">
                                            <h1 className="font-semibold text-sm md:text-base">{detail?.consultationsDisplayCount ?? ""}</h1>
                                            <h2 className="text-gray-700 text-sm md:text-base">Consultations</h2>

                                        </div>
                                        <div className="flex flex-col items-center">
                                            <h1 className="font-semibold text-sm md:text-base">{detail?.rating ?? ""}</h1>
                                            <h2 className="text-gray-700 text-sm md:text-base">Rating</h2>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="absolute top-16 w-full flex justify-center">
                                {detail?.imgUrl ? <img loading="lazy" src={detail?.imgUrl} className="object-contain rounded-full h-36 w-36 bg-gray-100 border border-white shadow-lg" alt={detail?.name} title={detail?.name}/> : <img loading="lazy" src={i} className="object-contain rounded-full h-36 w-36 bg-gray-100 border border-white shadow-lg" alt="" />}
                            </div>
                        </div>
                        <div className="flex justify-center invisible md:visible">

                            <div className="w-10/12 flex p-2 bg-white justify-around">
                                {detail.isConnectModeCall &&( detail?.status === 1 ? <button className="bg-[#87BD36] w-full rounded-xl text-white text-xs lg:text-base shadow object-contain p-1" onClick={async() => {if(user.name!== undefined){
                                    gtmCustomEvent('call_chat');
                                    let s = await initiateCall(detail?.user, detail?.imgUrl); 
                                    let d = detail;
                                    d.status = s;
                                    setdetail({...d})
                                } else {
                                    setshowLogin(true);
                                }
                                }}>
                                    <div className="flex flex-col items-center">
                                        <h4>Call</h4>
                                        <div className="flex"><h4 className="line-through">{detail?.newUserSinglePriceConsultAvailable && config?.flatPriceOfferCampaign ? '' : (user?.currencyType ==='usd'?'$':'₹') + (user?.currencyType==='usd'?detail?.actualCallRate?.usd:detail?.actualCallRate?.inr) + ","}</h4> <h4 className="ml-1">{ detail?.newUserSinglePriceConsultAvailable && config?.flatPriceOfferCampaign  ? config?.buttonText :(user?.currencyType ==='usd'?'$':'₹') + (user?.currencyType==='usd'?detail?.callRate?.usd:detail?.callRate?.inr) + '/Min'}</h4></div>

                                    </div>
                                </button> : detail?.status === 2 ? <button disabled className="bg-gradient-to-r from-[#FEB458] to-[#ED9108] w-full rounded-xl text-white text-xs lg:text-base shadow object-contain p-1">
                                    <div className="flex flex-col items-center">
                                        <h2 className="p-2">Busy</h2>
                                    </div>
                                </button> : <button disabled className="bg-gray-100 w-full rounded-xl text-gray-300 text-xs lg:text-base shadow object-contain p-1">
                                    <div className="flex flex-col items-center">
                                        <h4>Call</h4>
                                        <div className="flex"><h4 className="line-through">{(user?.currencyType ==='usd'?'$':'₹') + (user?.currencyType==='usd'?detail?.actualCallRate?.usd:detail?.actualCallRate?.inr) + ","}</h4> <h4 className="ml-1">{(user?.currencyType ==='usd'?'$':'₹') + (user?.currencyType==='usd'?detail?.callRate?.usd:detail?.callRate?.inr) + '/Min'}</h4></div>

                                    </div>
                                </button>)}
                                <div className="w-2 h-full"></div>
                                {detail.isConnectModeChat &&( detail?.status === 1 ? <button className="bg-[#87BD36] w-full rounded-xl text-white text-xs lg:text-base shadow object-contain p-1" onClick={async() => {if(user.name!== undefined){
                                    gtmCustomEvent('call_chat');
                                    let s = await initiateChat(detail?.user, detail?.imgUrl); 
                                    let d = detail;
                                    d.status = s;
                                    setdetail({...d});
                                } else {
                                    setshowLogin(true);
                                }
                                }}>
                                    <div className="flex flex-col items-center">
                                        <h4>Chat</h4>
                                        <div className="flex"><h4 className="line-through">{detail?.newUserSinglePriceConsultAvailable && config?.flatPriceOfferCampaign ? '' :(user?.currencyType ==='usd'?'$':'₹') + (user?.currencyType==='usd'?detail?.actualCallRate?.usd:detail?.actualCallRate?.inr) + ","}</h4> <h4 className="ml-1">{detail?.newUserSinglePriceConsultAvailable && config?.flatPriceOfferCampaign ? config?.buttonText :(user?.currencyType ==='usd'?'$':'₹') + (user?.currencyType==='usd'?detail?.callRate?.usd:detail?.callRate?.inr) + '/Min'}</h4></div>

                                    </div>
                                </button> : detail?.status === 2 ? <button disabled className="bg-gradient-to-r from-[#FEB458] to-[#ED9108] w-full rounded-xl text-white text-xs lg:text-base shadow object-contain p-1">
                                    <div className="flex flex-col items-center">
                                        <h2 className="p-2">Busy</h2>
                                    </div>
                                </button> : <button disabled className="bg-gray-100 w-full rounded-xl text-gray-300 text-xs lg:text-base shadow object-contain p-1">
                                    <div className="flex flex-col items-center">
                                        <h4>Chat</h4>
                                        <div className="flex"><h4 className="line-through">{(user?.currencyType ==='usd'?'$':'₹') + (user?.currencyType==='usd'?detail?.actualCallRate?.usd:detail?.actualCallRate?.inr) + ","}</h4> <h4 className="ml-1">{(user?.currencyType ==='usd'?'$':'₹') + (user?.currencyType==='usd'?detail?.callRate?.usd:detail?.callRate?.inr) + '/Min'}</h4></div>

                                    </div>
                                </button>)}

                            </div>
                        </div>

                    </div>

                    <div className="w-full lg:w-1/2 lg:m-10 md:rounded-xl md:border">
                        {/* <div className="flex items-center lg:mt-24">
                            <div className="flex py-2 mx-2">
                                <div className="h-10 w-10 rounded-full flex justify-center items-center border border-yellow-500">

                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                </div>
                                <h1 className="ml-2 mt-2">{'City - ' + detail?.city}</h1>
                            </div>
                        </div> */}
                            <h3 className="ml-4 md:mt-2 text-lg font-semibold">About Astro</h3>
                            <h3 className="mx-4 md:mt-2">{detail?.about}</h3>
                        <div className="flex items-center mb-20">
                            <div className="flex py-2 mt-2 ml-2 mr-3 mb-16">
                                <div className="h-10 w-10 rounded-full flex justify-center items-center border border-[#FB5B1D]">

                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 m-2" fill="none" viewBox="0 0 24 24" stroke="#FB5B1D">
                                        <path d="M12 14l9-5-9-5-9 5 9 5z" />
                                        <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
                                    </svg>
                                </div>
                                <h1 className="ml-2 mt-2 w-full">{'Education - ' + getEducationText(detail?.education)}</h1>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
            <div className="absolute w-full bottom-0 h-16 flex p-2 bg-white justify-around visible md:invisible">
                {detail.isConnectModeCall &&( detail?.status === 1 ? <button className="bg-[#87BD36] w-full rounded-xl text-white text-xs shadow object-contain p-1" onClick={() => { 
                    if(user.name!== undefined){
                        gtmCustomEvent('call_chat')
                        initiateCall(detail?.user, detail?.imgUrl); 
                    } else {
                        setshowLogin(true);
                    }
                 }}>
                    <div className="flex flex-col items-center">
                        <h4>Call</h4>
                        <div className="flex"><h4 className="line-through">{(user?.currencyType ==='usd'?'$':'₹') + (user?.currencyType==='usd'?detail?.actualCallRate?.usd:detail?.actualCallRate?.inr) + ","}</h4> <h4 className="ml-1">{detail?.newUserSinglePriceConsultAvailable && config ? config?.buttonText :(user?.currencyType ==='usd'?'$':'₹') + (user?.currencyType==='usd'?detail?.callRate?.usd:detail?.callRate?.inr) + '/Min'}</h4></div>

                    </div>
                </button> : detail?.status === 2 ? <button disabled className="bg-gradient-to-r from-[#FEB458] to-[#ED9108] w-full rounded-xl text-white text-xs shadow object-contain p-1">
                    <div className="flex flex-col items-center">
                        <h2 className="p-2">Busy</h2>
                    </div>
                </button> : <button disabled className="bg-gray-100 w-full rounded-xl text-gray-300 text-xs shadow object-contain p-1">
                    <div className="flex flex-col items-center">
                        <h4>Call</h4>
                        <div className="flex"><h4 className="line-through">{(user?.currencyType ==='usd'?'$':'₹') + (user?.currencyType==='usd'?detail?.actualCallRate?.usd:detail?.actualCallRate?.inr) + ","}</h4> <h4 className="ml-1">{(user?.currencyType ==='usd'?'$':'₹') + (user?.currencyType==='usd'?detail?.callRate?.usd:detail?.callRate?.inr) + '/Min'}</h4></div>

                    </div>
                </button>)}
                <div className="w-2 h-full"></div>
                {detail.isConnectModeChat&& ( detail?.status === 1 ? <button className="bg-[#87BD36] w-full rounded-xl text-white text-xs shadow object-contain p-1" onClick={() => { 
                    if(user.name!== undefined){
                        gtmCustomEvent('call_chat')
                        initiateChat(detail?.user, detail?.imgUrl); 
                    } else {
                        setshowLogin(true);
                    }
                 }}>
                    <div className="flex flex-col items-center">
                        <h4>Chat</h4>
                        <div className="flex"><h4 className="line-through">{(user?.currencyType ==='usd'?'$':'₹') + (user?.currencyType==='usd'?detail?.actualCallRate?.usd:detail?.actualCallRate?.inr) + ","}</h4> <h4 className="ml-1">{detail?.newUserSinglePriceConsultAvailable && config?.flatPriceOfferCampaign ? config?.buttonText :(user?.currencyType ==='usd'?'$':'₹') + (user?.currencyType==='usd'?detail?.callRate?.usd:detail?.callRate?.inr) + '/Min'}</h4></div>

                    </div>
                </button> : detail?.status === 2 ? <button disabled className="bg-gradient-to-r from-[#FEB458] to-[#ED9108] w-full rounded-xl text-white text-xs shadow object-contain p-1">
                    <div className="flex flex-col items-center">
                        <h2 className="p-2">Busy</h2>
                    </div>
                </button> : <button disabled className="bg-gray-100 w-full rounded-xl text-gray-300 text-xs shadow object-contain p-1">
                    <div className="flex flex-col items-center">
                        <h4>Chat</h4>
                        <div className="flex"><h4 className="line-through">{(user?.currencyType ==='usd'?'$':'₹') + (user?.currencyType==='usd'?detail?.actualCallRate?.usd:detail?.actualCallRate?.inr) + ","}</h4> <h4 className="ml-1">{(user?.currencyType ==='usd'?'$':'₹') + (user?.currencyType==='usd'?detail?.callRate?.usd:detail?.callRate?.inr) + '/Min'}</h4></div>

                    </div>
                </button>)}

            </div>


        </div >
    )
}

export default Astrologers
